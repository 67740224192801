@import "./theme"
$orderedList: orderedList
$size: 1.2rem

@mixin contain-content-area($padding-x)
  padding-left: $padding-x

  > div.page
    max-width: calc(100% - #{$padding-x})
    min-height: 100vh 


html, body, div#root > div.content, .vh-100
  min-height: 100vh

div.content
  position: relative

  > div.content-area
    min-width: 100%
    @include contain-content-area($padding-x: 4rem)

    @media screen and ( max-width: $large )
      @include contain-content-area($padding-x: 2rem)

    @media screen and ( max-width: $medium )
      @include contain-content-area($padding-x: 1rem)

    @media screen and ( max-width: $small )
      @include contain-content-area($padding-x: 0.5rem)


body
  background: linear-gradient($background1 88%, $background1)
@import "../theme"

div.reaction-edit
    position: relative
    font-size: small

    button.close-btn
        position: absolute
        top: -2em
        right: 1em
        color: $danger
        background-color: $background4
        border: 1px solid $border1
        z-index: 10

    input:not([type=range]), textarea
        background-color: $background2
        border-color: $border1
        color: white

    span.input-group-text
        background-color: $background3
        border-color: $border1
        color: $color1
div.not-found-page

    height: 100vh
    position: relative
    
    h1
        top: 9rem
        left: 50%
        margin-left: -25rem
        font-size: 30rem
        position: fixed
        z-index: 100

    img
        position: fixed
        right: 0
        bottom: 0
        z-index: 0
@import "../theme"

@mixin reaction-card()
    margin: 0.1em
    background-color: $background3
    width: 100%
    padding: 0.8em 0 1em 0
    border-radius: 7px
    border: 1px solid $border1
    position: relative

div.reaction-card
    @include reaction-card()

    .hover-btn-group
        button
            color: $color1
            background-color: $background4
            border: 1px solid $border1
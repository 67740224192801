@import "../theme"


div.volume-control
    
    svg
        font-size: 1.6em

    input[type='range']
        width: 1em
        -webkit-appearance: slider-vertical
        
        background-color: black
@import "../theme"

div.persona-name-edit-group
    input:not([type=range])
        background-color: $background2
        border-color: $border1
        color: white
        font-size: 1.5em
        line-height: 1em
        width: 100%
        padding-top: 0
        padding-bottom: 0.2em
        padding-left: 0.1em
        margin-top: -0.14em
        margin-left: -0.14em
        margin-bottom: 0.2em
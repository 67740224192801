$background1: #060606
$background2: #191a1b
$background3: #0e0e10
$background4: #17171a
$border1: #141313
$color1: #a7aaac

$primary: #FF73FA
$secondary: #9B84EE
$success: #43B571
$danger: #F04747
$warning: #FAA61A
$info: #44DDBF

$small: 480px
$medium: 960px
$large: 1440px

$theme-colors: ("primary": $primary, "secondary": $secondary, "success": $success, "danger": $danger, "warning": $warning, "info": $info)

@import "bootstrap/scss/bootstrap"

body
    font-family: 'Whitney', monospace
    color: #dcddde !important
@import "sass-embedded-legacy-load-done:75"
@import "../theme"

.manage-buttons
    top: 0.1em
    right: -0.3em

    button
        border: none
        background: none

        &.remove > svg
            text-shadow: 0 2px 10px black
            margin-bottom: -0.07em
            color: $danger

        &.edit > svg
            text-shadow: 0 2px 10px black
            margin-left: 0.1em
            color: white



.manage-buttons-overlay
    width: 100%
    height: 100%
    border-radius: 5px
    overflow: hidden

    button
        border: none
        opacity: 0.7

        &:hover
            opacity: 1

        &.remove
            width: 36%
            background: linear-gradient(rgba(21, 13, 13, 0.432) 76%, rgba(24, 16, 16, 0.542))

            > svg
                margin-bottom: -0.07em
                color: $danger

        &.edit
            width: 64%
            background: linear-gradient(rgba(19, 19, 19, 0.412) 56%, rgba(7, 7, 7, 0.569))

            > svg
                color: white
@import "../theme"

@mixin persona-card()
    margin: 0.1em
    width: 100%
    background-color: $background2
    border-radius: 7px
    padding: 1em
    position: relative

div.persona-card
    @include persona-card()

    > div.content
        width: 100%
        
        > div.children
            width: 100%
    
    button.remove
        color: $color1
        background-color: $background2
        border: 1px solid $border1